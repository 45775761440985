<template>
  <section class="concert-head">
    <div
      class="concert-head-inner"
      :style="{
        'background-image':
          'url(' + `${publicPath}assets/images/concert-head-bg.png` + ')'
      }"
    >
      <div class="concert-head-content">
        <div class="container">
          <h1 class="head-title">
            <span
              class="d-none d-sm-block"
              v-html="$t(`concertPage.downloadTheApp`)"
            ></span>
            <span
              class="d-sm-none"
              v-html="$t(`concertPage.downloadTheAppMobile`)"
            ></span>
          </h1>
        </div>
      </div>
      <div class="concert-head-stores-wrap container">
        <UiSocial></UiSocial>
      </div>
    </div>
  </section>
</template>

<script>
import UiSocial from "@/components/UI/UiSocial.vue";
export default {
  name: "Concert",
  metaInfo() {
    return {
      title: this.$t("meta.concert.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      }
    };
  },
  components: {
    UiSocial
  }
};
</script>
